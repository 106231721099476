import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { SideNavigation } from 'hds-react';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/side-navigation/customisation",
  "title": "SideNavigation - Customisation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component. See all available theme variables in the table below. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-active-indicator-background-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the active indicator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-background-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background colour of the side navigation container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-icon-size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigation item icon size (if icons are used)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-level-background-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background colour of a navigation item (not active)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-level-background-color-active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background colour of an active navigation item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-level-background-color-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background colour of a hovered navigation item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-level-border-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of a border around a navigation item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-level-border-color-focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of a focus border around a navigation item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-level-border-color-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of a border around a hovered navigation item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-level-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of text and icon elements inside a navigation item (not active)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-level-color-active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of text and icon elements inside an active navigatio item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-level-color-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of text and icon elements inside a hovered navigatio item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-mobile-menu-border-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of a border around an opened mobile side navigation menu`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--side-navigation-mobile-menu-z-index`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Z-index value of an opened mobile side navigation menu`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [active, setActive] = React.useState('/archive');
  const setActivePage = (e) => {
    e.preventDefault();
    setActive(e.target.getAttribute('href'));
  }
  return (<div
    style={{
      backgroundColor: '#f5f5f5',
      display: 'grid',
    }}
  >
    <div
      style={{
        height: '100%',
        maxWidth: '400px'
      }}
    >
      <SideNavigation
        defaultOpenMainLevels={[]}
        id="side-navigation"
        toggleButtonLabel="Navigate to page"
        theme={{
          '--side-navigation-active-indicator-background-color': 'var(--color-gold)',
          '--side-navigation-background-color': 'var(--color-white)',
          '--side-navigation-icon-size': 'var(--spacing-m)',
          '--side-navigation-level-background-color': 'var(--color-white)',
          '--side-navigation-level-background-color-active': 'var(--color-fog-light)',
          '--side-navigation-level-background-color-hover': 'var(--color-fog-light)',
          '--side-navigation-level-border-color': 'var(--color-white)',
          '--side-navigation-level-border-color-focus': 'var(--color-coat-of-arms)',
          '--side-navigation-level-border-color-hover': 'var(--color-fog-light)',
          '--side-navigation-level-color': 'var(--color-black)',
          '--side-navigation-level-color-active': 'var(--color-black)',
          '--side-navigation-level-color-hover': 'var(--color-black)',
          '--side-navigation-mobile-menu-border-color': 'var(--color-black)',
          '--side-navigation-mobile-menu-z-index': 100
        }}  
      >
        <SideNavigation.MainLevel
          id="#main-level-1"
          label="Events"
        >
          <SideNavigation.SubLevel
            href="/events"
            active={active === '/events'}
            id="sub-level-1"
            label="All events"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/archive"
            active={active === '/archive'}
            id="sub-level-2"
            label="Archive"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/new-event"
            active={active === '/new-event'}
            id="sub-level-3"
            label="Create a new event"
            onClick={setActivePage}
          />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel
          id="main-level-2"
          label="Events on map"
        >
          <SideNavigation.SubLevel
            href="/map"
            active={active === '/map'}
            id="sub-level-4"
            label="Map"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/edit-event-locations"
            active={active === '/edit-event-locations'}
            id="sub-level-5"
            label="Edit event locations"
            onClick={setActivePage}
          />
          <SideNavigation.SubLevel
            href="/new-location"
            active={active === '/new-location'}
            id="sub-level-6"
            label="Create a new location"
            onClick={setActivePage}
          />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel
          href="/settings"
          active={active === '/settings'}
          id="main-level-3"
          label="Settings"
          onClick={setActivePage}
        />
        <SideNavigation.MainLevel
          external
          href="https://tapahtumat.hel.fi/"
          openInNewTab
          openInNewTabAriaLabel="Opens in a new tab."
          openInExternalDomainAriaLabel="Opens a different website."
          id="main-level-4"
          label="Tapahtumat.hel.fi"
          openInExternalDomainAriaLabel="Opens a different website"
          withDivider
        />
      </SideNavigation>
    </div>
  </div>)
}}
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      